import { NgIf, AsyncPipe, NgTemplateOutlet, formatDate } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, inject, TemplateRef, Directive, Input, Component, ViewEncapsulation, ChangeDetectionStrategy, ContentChildren, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_FORM_FIELD } from '@angular/material/form-field';
import { isObservable, of, pairwise, filter, merge, startWith, map, combineLatest, ReplaySubject } from 'rxjs';
import { switchMap, startWith as startWith$1, map as map$1, distinctUntilChanged } from 'rxjs/operators';
import { ControlContainer, AbstractControl, AbstractControlDirective } from '@angular/forms';
import { coerceArray } from '@angular/cdk/coercion';
function NgxMatErrors_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const error_r1 = ctx.$implicit;
    i0.ɵɵtextInterpolate(error_r1);
  }
}
function NgxMatErrors_ng_template_2_ng_template_0_Template(rf, ctx) {}
function NgxMatErrors_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NgxMatErrors_ng_template_2_ng_template_0_Template, 0, 0, "ng-template", 2);
  }
  if (rf & 2) {
    let tmp_3_0;
    const error_r2 = ctx.$implicit;
    i0.ɵɵnextContext();
    const defaultTemplate_r3 = i0.ɵɵreference(1);
    i0.ɵɵproperty("ngTemplateOutlet", (tmp_3_0 = error_r2.template) !== null && tmp_3_0 !== undefined ? tmp_3_0 : defaultTemplate_r3)("ngTemplateOutletContext", error_r2);
  }
}
const NGX_MAT_ERROR_DEF = new InjectionToken('NGX_MAT_ERROR_DEF');
class NgxMatErrorDef {
  constructor() {
    /**
     * Specify the control to be used for error matching.
     * @optional
     */
    this.ngxMatErrorDefWithControl = undefined;
    this.template = inject(TemplateRef);
    this.controlContainer = inject(ControlContainer, {
      optional: true,
      skipSelf: true
    });
  }
  get control() {
    const input = this.ngxMatErrorDefWithControl;
    if (typeof input === 'string') {
      return this.controlContainer?.control?.get(input) ?? undefined;
    }
    if (input instanceof AbstractControl) {
      return input;
    }
    return input?.control ?? undefined;
  }
  static {
    this.ɵfac = function NgxMatErrorDef_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMatErrorDef)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxMatErrorDef,
      selectors: [["", "ngxMatErrorDef", ""]],
      inputs: {
        ngxMatErrorDefFor: "ngxMatErrorDefFor",
        ngxMatErrorDefWithControl: "ngxMatErrorDefWithControl"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NGX_MAT_ERROR_DEF,
        useExisting: NgxMatErrorDef
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatErrorDef, [{
    type: Directive,
    args: [{
      selector: '[ngxMatErrorDef]',
      standalone: true,
      providers: [{
        provide: NGX_MAT_ERROR_DEF,
        useExisting: NgxMatErrorDef
      }]
    }]
  }], null, {
    ngxMatErrorDefFor: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    ngxMatErrorDefWithControl: [{
      type: Input
    }]
  });
})();
function coerceToObservable(errorMessages) {
  if (isObservable(errorMessages)) {
    return errorMessages;
  }
  return of(errorMessages);
}
function distinctUntilErrorChanged(prev, curr) {
  if (prev === curr) {
    return true;
  }
  if (!prev || !curr) {
    return false;
  }
  if (prev.template !== curr.template) {
    return false;
  }
  return prev.$implicit === curr.$implicit;
}

/**
 * Find the custom error for a control.
 *
 * @returns INgxMatErrorDef | undefined
 */
function findCustomErrorForControl(errorKeys, customErrorMessages, control) {
  return customErrorMessages.find(customErrorMessage => {
    return errorKeys.some(error => {
      if (error !== customErrorMessage.ngxMatErrorDefFor) return false;
      return !customErrorMessage.control || customErrorMessage.control === control;
    });
  });
}
function getAbstractControls(controls) {
  if (!controls) {
    return;
  }
  const _controls = coerceArray(controls).map(control => !control ? undefined : control instanceof AbstractControlDirective ? control.control : control instanceof AbstractControl ? control : control.ngControl?.control).filter(control => control != null);
  return _controls.length ? _controls : undefined;
}
function getControlWithError(controls) {
  const controlChanges = controls.map(control => {
    const fromPendingStates = control.statusChanges.pipe(pairwise(), filter(([previous, current]) => {
      return previous === 'PENDING' && current !== 'PENDING';
    }));
    return merge(control.valueChanges, fromPendingStates).pipe(startWith(null), map(() => control));
  });
  return combineLatest(controlChanges).pipe(map(control => control.find(control => !!control.errors)));
}
const NGX_MAT_ERROR_DEFAULT_OPTIONS = new InjectionToken('NGX_MAT_ERROR_DEFAULT_OPTIONS');
class NgxMatErrors {
  constructor() {
    this.messages$ = coerceToObservable(inject(NGX_MAT_ERROR_DEFAULT_OPTIONS));
    this.matFormField = inject(MAT_FORM_FIELD, {
      optional: true
    });
    this.controlChangedSubject = new ReplaySubject(1);
  }
  // ContentChildren is set before ngAfterContentInit which is before ngAfterViewInit.
  // Before ngAfterViewInit lifecycle hook we can modify the error$ observable without needing another change detection cycle.
  // This elaborates the need of rxjs defer;
  set customErrorMessages(queryList) {
    const firstControlWithError$ = this.controlChangedSubject.pipe(switchMap(_controls => {
        const controls = getAbstractControls(_controls || this.matFormField?._control);
        if (!controls) {
          return of(null);
        }
        return getControlWithError(controls);
      })),
      customErrorMessages$ = queryList.changes.pipe(startWith$1(queryList));
    this.error$ = combineLatest([firstControlWithError$, customErrorMessages$, this.messages$]).pipe(map$1(([controlWithError, customErrorMessages, messages]) => {
      if (!controlWithError) {
        return;
      }
      const errors = controlWithError.errors;
      const errorKeys = Object.keys(errors);
      const errorOrErrorDef = findCustomErrorForControl(errorKeys, customErrorMessages.toArray(), controlWithError) ?? errorKeys.find(key => key in messages);
      if (!errorOrErrorDef) {
        return;
      }
      if (typeof errorOrErrorDef === 'object') {
        return {
          template: errorOrErrorDef.template,
          $implicit: errors[errorOrErrorDef.ngxMatErrorDefFor]
        };
      }
      const message = messages[errorOrErrorDef];
      return {
        $implicit: typeof message === 'function' ? message(errors[errorOrErrorDef]) : message
      };
    }), distinctUntilChanged(distinctUntilErrorChanged));
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set control(control) {
    this.controlChangedSubject.next(control);
  }
  ngOnDestroy() {
    this.controlChangedSubject.complete();
  }
  static {
    this.ɵfac = function NgxMatErrors_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMatErrors)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxMatErrors,
      selectors: [["ngx-mat-errors"], ["", "ngx-mat-errors", ""]],
      contentQueries: function NgxMatErrors_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NGX_MAT_ERROR_DEF, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customErrorMessages = _t);
        }
      },
      hostAttrs: [1, "ngx-mat-errors"],
      inputs: {
        control: [0, "ngx-mat-errors", "control"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 3,
      consts: [["defaultTemplate", ""], [3, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function NgxMatErrors_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, NgxMatErrors_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(2, NgxMatErrors_ng_template_2_Template, 1, 2, "ng-template", 1);
          i0.ɵɵpipe(3, "async");
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 1, ctx.error$));
        }
      },
      dependencies: [NgIf, AsyncPipe, NgTemplateOutlet],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatErrors, [{
    type: Component,
    args: [{
      selector: 'ngx-mat-errors, [ngx-mat-errors]',
      template: `<ng-template #defaultTemplate let-error>{{ error }}</ng-template
    ><ng-template [ngIf]="error$ | async" let-error
      ><ng-template
        [ngTemplateOutlet]="error.template ?? defaultTemplate"
        [ngTemplateOutletContext]="error"
      ></ng-template>
    </ng-template>`,
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [NgIf, AsyncPipe, NgTemplateOutlet],
      host: {
        class: 'ngx-mat-errors'
      }
    }]
  }], null, {
    customErrorMessages: [{
      type: ContentChildren,
      args: [NGX_MAT_ERROR_DEF, {
        descendants: true
      }]
    }],
    control: [{
      type: Input,
      args: ['ngx-mat-errors']
    }]
  });
})();
function errorMessagesEnFactory(locale, format = 'shortDate') {
  return {
    min: error => `Please enter a value greater than or equal to ${error.min}.`,
    max: error => `Please enter a value less than or equal to ${error.max}.`,
    required: `This field is required.`,
    email: `Please enter a valid email address.`,
    minlength: error => `Please enter at least ${error.requiredLength} characters.`,
    maxlength: error => `Please enter no more than ${error.requiredLength} characters.`,
    matDatepickerMin: error => {
      const formatted = formatDate(error.min, format, locale);
      return `Please enter a date greater than or equal to ${formatted ?? error.min}.`;
    },
    matDatepickerMax: error => {
      const formatted = formatDate(error.max, format, locale);
      return `Please enter a date less than or equal to ${formatted ?? error.max}.`;
    },
    matDatepickerParse: error => `Invalid date format.`,
    matStartDateInvalid: error => `Start date cannot be after end date.`,
    matEndDateInvalid: error => `End date cannot be before start date.`,
    matDatepickerFilter: 'This date is filtered out.'
  };
}
const NGX_MAT_ERROR_CONFIG_EN = {
  provide: NGX_MAT_ERROR_DEFAULT_OPTIONS,
  useFactory: errorMessagesEnFactory,
  deps: [LOCALE_ID]
};
function errorMessagesHuFactory(locale, format = 'shortDate') {
  return {
    min: error => `Nem lehet kisebb, mint ${error.min}.`,
    max: error => `Nem lehet nagyobb, mint ${error.max}.`,
    required: `Kötelező mező.`,
    email: `Nem érvényes e-mail cím.`,
    minlength: error => `Legalább ${error.requiredLength} karakter hosszú lehet.`,
    maxlength: error => `Legfeljebb ${error.requiredLength} karakter hosszú lehet.`,
    server: error => error,
    matDatepickerMin: error => {
      const formatted = formatDate(error.min, format, locale);
      // In Hungarian date ends with '.'
      return `Nem lehet korábbi dátum, mint ${formatted ?? error.min}`;
    },
    matDatepickerMax: error => {
      const formatted = formatDate(error.max, format, locale);
      // In Hungarian date ends with '.'
      return `Nem lehet későbbi dátum, mint ${formatted ?? error.max}`;
    },
    matDatepickerParse: error => `Érvénytelen dátum.`,
    matStartDateInvalid: error => `A kezdő dátum nem lehet a vég dátum után.`,
    matEndDateInvalid: error => `A vég dátum nem lehe a kezdő dátum előtt.`,
    matDatepickerFilter: 'Ez a dátum nem engedélyezett.'
  };
}
const NGX_MAT_ERROR_CONFIG_HU = {
  provide: NGX_MAT_ERROR_DEFAULT_OPTIONS,
  useFactory: errorMessagesHuFactory,
  deps: [LOCALE_ID]
};
function errorMessagesPtBtFactory(locale, format = 'shortDate') {
  return {
    min: error => `Informe um valor igual ou maior a ${error.min}.`,
    max: error => `Informe um valor igual ou menor a ${error.max}.`,
    required: `Campo obrigatório.`,
    email: `Informe um endereço de email válido.`,
    minlength: error => `Informe pelo menos ${error.requiredLength} caracteres.`,
    maxlength: error => `O campo não pode ter mais que ${error.requiredLength} caracteres.`,
    matDatepickerMin: error => {
      const formatted = formatDate(error.min, format, locale);
      return `Informe uma data maior ou igual a ${formatted ?? error.min}.`;
    },
    matDatepickerMax: error => {
      const formatted = formatDate(error.max, format, locale);
      return `Informe uma data menor ou igual a ${formatted ?? error.max}.`;
    },
    matDatepickerParse: error => `Invalid date format.`,
    matStartDateInvalid: error => `Start date cannot be after end date.`,
    matEndDateInvalid: error => `End date cannot be before start date.`,
    matDatepickerFilter: 'This date is filtered out.'
  };
}
const NGX_MAT_ERROR_CONFIG_PT_BR = {
  provide: NGX_MAT_ERROR_DEFAULT_OPTIONS,
  useFactory: errorMessagesPtBtFactory,
  deps: [LOCALE_ID]
};
class NgxMatErrorsForDateRangePicker {
  constructor() {
    this.ngxMatErrors = inject(NgxMatErrors);
    this.matFormField = inject(MAT_FORM_FIELD);
  }
  ngAfterContentInit() {
    const control = this.matFormField._control;
    this.ngxMatErrors.control = [control._startInput.ngControl, control._endInput.ngControl];
  }
  static {
    this.ɵfac = function NgxMatErrorsForDateRangePicker_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMatErrorsForDateRangePicker)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxMatErrorsForDateRangePicker,
      selectors: [["", "ngx-mat-errors", "", "forDateRangePicker", ""]],
      hostAttrs: [1, "ngx-mat-errors-for-date-range-picker"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatErrorsForDateRangePicker, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: '[ngx-mat-errors][forDateRangePicker]',
      standalone: true,
      host: {
        class: 'ngx-mat-errors-for-date-range-picker'
      }
    }]
  }], null, null);
})();
class NgxMatErrorsModule {
  static {
    this.ɵfac = function NgxMatErrorsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxMatErrorsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMatErrorsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatErrorsModule, [{
    type: NgModule,
    args: [{
      imports: [NgxMatErrors, NgxMatErrorDef],
      exports: [NgxMatErrors, NgxMatErrorDef]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-mat-errors
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NGX_MAT_ERROR_CONFIG_EN, NGX_MAT_ERROR_CONFIG_HU, NGX_MAT_ERROR_CONFIG_PT_BR, NGX_MAT_ERROR_DEF, NGX_MAT_ERROR_DEFAULT_OPTIONS, NgxMatErrorDef, NgxMatErrors, NgxMatErrorsForDateRangePicker, NgxMatErrorsModule, errorMessagesEnFactory, errorMessagesHuFactory, errorMessagesPtBtFactory };
