import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
  ) { }

  /**
   * Presents a toast displaying the message with a green background
   * @param message Message to display
   * @param action Action to apply
   * @example
   * this.notificationService.success("confirm oked");
   */
  success(message: string, action: string = 'hide', duration = 3000) {
    this.openSnackBar(message, action, 'success-snackbar', duration);
  }

  /**
   * Presents a toast displaying the message with a red background
   * @param message Message to display
   * @param action Action to apply
   * @example
   * this.notificationService.error("confirm canceled");
   */
  error(message: string, action: string = 'hide', duration = 500000) {
    this.openSnackBar(message, action, 'error-snackbar', duration);
  }

  /**
   * Displays a toast with provided message
   * @param message Message to display
   * @param action Action text, e.g. Close, Done, etc
   * @param className Optional extra css class to apply
   * @param duration Optional number of SECONDS to display the notification for
   */
  openSnackBar(
    message: string,
    action: string,
    className = '',
    duration = 500000
  ) {
    const snackBarRef = this.snackBar.open(message, action, {
      duration: duration,
      panelClass: [className],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss()
    })

    return snackBarRef;
  }
}
